import React, { useEffect } from 'react'
import DashboardPage from '../dashboard/DashboardPage'
import NotificationsPage from '../notifications/NotificationsPage'

import { Redirect } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import {
  mainSelector,
  refreshAPI,
  getCurrentUser,
} from './mainSlice'


const MainPage = ({ match }) => {
  const { isRefreshed, currentUser, loading } = useSelector(mainSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if(localStorage.getItem('bishop_current_user')){
      dispatch(refreshAPI())
    }
  }, [dispatch, match])

  if(isRefreshed&&!loading&&!currentUser){
    dispatch(getCurrentUser())
  }

  if(isRefreshed){
    return (
      <div>
        <DashboardPage/>
        <NotificationsPage/>
      </div>
    )
  }

  if(!isRefreshed&&!localStorage.getItem('bishop_current_user')){
    return (
        <Redirect to={"/login"}/>
    )
  }

  return null;
}
export default MainPage
