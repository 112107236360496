import React from 'react'
import { Typography, Container, Table, TableCell, TableRow, TableBody, Divider, Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@material-ui/core'
import T from 'i18n-react';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
const useStyles = makeStyles((theme) => ({
  root: {
    width:"100%",
    paddingTop:theme.spacing(2)
  },
  buttonContainer:{
    textAlign:"right",
  }
}));

export const Details = ({ machine, edit, toggleDelete, deleting, doDelete }) => {

  const classes = useStyles();

  return (
    <Container maxWidth={false} className={classes.root}>
        <Typography component="h1" variant="h6" color="inherit"> {T.translate("machine.machine")}</Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{T.translate("machine.code")}</TableCell>
              <TableCell>{machine.code}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{T.translate("machineType.producer")}</TableCell>
              <TableCell>{machine.type.producer}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{T.translate("machineType.model")}</TableCell>
              <TableCell>{machine.type.model}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{T.translate("machineType.description")}</TableCell>
              <TableCell>{machine.type.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{T.translate("machine.volumeUnit")}</TableCell>
              <TableCell>{machine.volumeUnit}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{T.translate("machine.uuid")}</TableCell>
              <TableCell>{machine.uuid}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{T.translate("machine.boxNumber")}</TableCell>
              <TableCell>{machine.boxNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{T.translate("machine.ultrafabNumber")}</TableCell>
              <TableCell>{machine.ultrafabNumber}</TableCell>
            </TableRow>
            {machine.type.id===3?(
              <React.Fragment>
                <TableRow>
                  <TableCell>{T.translate("machine.enableSamp1")}</TableCell>
                  <TableCell>{machine.enableSamp1?<CheckIcon/>:null}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{T.translate("machine.enableSamp3")}</TableCell>
                  <TableCell>{machine.enableSamp3?<CheckIcon/>:null}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{T.translate("machine.enableSamp4")}</TableCell>
                  <TableCell>{machine.enableSamp4?<CheckIcon/>:null}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{T.translate("machine.enableSamp5")}</TableCell>
                  <TableCell>{machine.enableSamp5?<CheckIcon/>:null}</TableCell>
                </TableRow>
              </React.Fragment>
            ):null}
          </TableBody>
        </Table>
      <Divider/>
      <div className={classes.buttonContainer}>
        <Button focusRipple key={machine.id} onClick={edit} color="primary">{T.translate("button.edit")}</Button>
        <Button color="secondary" onClick={toggleDelete}>{T.translate("button.delete")}</Button>
      </div>
      <Dialog
        open={deleting}
        keepMounted
        onClose={toggleDelete}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{T.translate("function.confirm")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{T.translate("function.confirmDelete")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={doDelete} color="secondary">{T.translate("button.confirm")}</Button>
          <Button onClick={toggleDelete} color="default">{T.translate("button.cancel")}</Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}
