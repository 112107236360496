import { createSlice } from '@reduxjs/toolkit'
import { showError, showSuccess } from '../notifications/notificationsSlice';
import { logout } from '../main/mainSlice';
import { SERVER_URL } from '../../constants';
import { reset as resetMachines } from '../machines/machinesSlice';
import { reset as resetPlants } from '../plants/plantsSlice';

export const initialState = {
  loading: false,
  hasErrors: false,
  editmode: false,
  machineType: false,
  machineTypeedit:false,
  deleting:false,
  isCreated:false,
  isDeleted:false,
}

const machineTypeSlice = createSlice({
  name: 'machineType',
  initialState,
  reducers: {
    reset: state => {
      state.machineType = false
      state.machineTypeedit = false
      state.editmode = false
      state.isDeleted = false
      state.isCreated = false
    },
    getting: state => {
      state.machineType = false
      state.isCreated = false
      state.isDeleted = false
      state.loading = true
      state.editmode = false
    },
    getSuccess: (state, { payload }) => {
      payload.confirmPassword = payload.password
      state.machineType = payload
      state.loading = false
      state.hasErrors = false
    },
    new: state => {
      state.machineType = {machineTypename:""}
    },
    creating: state => {
      state.loading = true
    },
    create: state => {
      let newmachineType = {"id":"new","producer":"","model":"","description":""}
      state.machineType = newmachineType
      state.machineTypeedit = newmachineType
      state.editmode = true
    },
    createSuccess: (state, { payload }) => {
      state.machineType = payload
      state.machineTypeedit = false
      state.loading = false
      state.hasErrors = false
      state.isCreated = true
    },
    resetEdit: state => {
      state.editmode = true
      state.machineTypeedit = state.machineType
    },
    edit: state => {
      state.editmode = true
      state.machineTypeedit = state.machineType
    },
    cancelEdit: state => {
      state.editmode = false
      state.machineTypeedit = false
    },
    change: (state, { payload }) => {
      let u = state.machineTypeedit
      u[payload.name] = payload.value
      let errorpassword = false
      if(u.id==="new"){
        errorpassword = state.machineTypeedit.password!==state.machineTypeedit.confirmPassword || state.machineTypeedit.password===""
      }else{
        errorpassword = state.machineTypeedit.password!==state.machineTypeedit.confirmPassword&&state.machineTypeedit.password!==""
      }
      u.errorpassword = errorpassword
      state.machineTypeedit = u
    },
    updating: state => {
      state.loading = true
    },
    updateSuccess: (state, { payload }) => {
      state.machineType = payload
      state.loading = false
      state.hasErrors = false
      state.editmode = false
    },
    confirmDelete: state => {
      state.deleting = !state.deleting
    },
    deleting: state => {
      state.deleting = false
      state.loading = true
      state.hasErrors = false
    },
    deleteSuccess: state => {
      state.loading = false
      state.hasErrors = false
      state.isDeleted = true
    },
  }
})

export const {
  reset, getting, getSuccess, create, creating, createSuccess,
  edit, cancelEdit, change , updating, updateSuccess,
  confirmDelete,deleting,deleteSuccess,resetEdit,
 } = machineTypeSlice.actions

export const machineTypeSelector = state => state.machineType

export default machineTypeSlice.reducer

export function updateAPI(machineType) {
  let access_token = ""
  if(localStorage.getItem('bishop_current_user')!=null){
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  let params = {machineType:machineType}
  return async dispatch => {
    dispatch(getting())
    const response = await fetch(SERVER_URL+'/api/machineType/'+machineType.id, {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body:JSON.stringify(params)
    });

    const data = await response.json()
    if(response.status===200){
      dispatch(updateSuccess(data))
      dispatch(showSuccess("machineType.success.update"))
    }else if(response.status===403){
      dispatch(logout())
    }else{
      dispatch(resetEdit())
      dispatch(showError(data.error))
    }
  }
}
export function createAPI(machineType) {
  let access_token = ""
  if(localStorage.getItem('bishop_current_user')!=null){
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(creating())
      let params = {machineType:machineType}
      const response = await fetch(SERVER_URL+'/api/machineType', {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'Authorization': 'Bearer ' + access_token
        },
        body: JSON.stringify(params)
      });

      const data = await response.json()
      if(response.status===200){
        dispatch(createSuccess(data))
        dispatch(showSuccess("machineType.success.create"))
      }else if(response.status===403){
        dispatch(logout())
      }else{
        dispatch(showError(data.message))
      }
  }
}

export function getAPI(id) {
  let access_token = ""
  if(localStorage.getItem('bishop_current_user')!=null){
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(getting())
    dispatch(resetMachines())
    dispatch(resetPlants())
    const response = await fetch(SERVER_URL+'/api/machineType/'+id, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    const data = await response.json()
    if(response.status===200){
      dispatch(getSuccess(data))
    }else if(response.status===403){
      dispatch(logout())
    }else{
      dispatch(showError(data.message))
    }
  }
}
export function deleteAPI(machineType) {
  let access_token = ""
  if(localStorage.getItem('bishop_current_user')!=null){
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(deleting())
    const response = await fetch(SERVER_URL+'/api/machineType/'+machineType.id, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    const data = await response.json()
    if(response.status===200){
      dispatch(deleteSuccess(data))
      dispatch(showSuccess("machineType.success.delete"))
    }else if(response.status===403){
      dispatch(logout())
    }else{
      dispatch(showError(data.message))
    }
  }
}
