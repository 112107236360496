import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Redirect,
} from 'react-router-dom'
import {
  userSelector,
  getAPI,
  updateAPI,
  createAPI,
  deleteAPI,
  edit,
  cancelEdit,
  change,
  create,
  confirmDelete,
  getRolesAPI,
  changeRoles,
} from './userSlice'

import {
  Box,Tabs,Tab,AppBar
} from "@material-ui/core";

import { Form } from './Form'
import { Details } from './Details'
import { PlantsPage } from '../plants/PlantsPage'
import UserDataPage from '../homepage/UserDataPage'

const UserPage = ({ match }) => {
  const dispatch = useDispatch()
  const { user, useredit, editmode, deleting, isCreated, isDeleted, roles, userRoles } = useSelector(userSelector)

  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = event => {
    if(event.target.name==="roles"){
      dispatch(changeRoles({value:event.target.checked, role:event.target.value}));
    }else{
      let name = event.target.name
      let value = event.target.value
      if(event.target.type==="checkbox"){
        value = event.target.checked
      }
      dispatch(change({name: name, value: value }));
    }
  };

  const toggleDelete = event => {
    dispatch(confirmDelete());
  };

  const onCancelEdit = () => {
    dispatch(cancelEdit(useredit))
  }

  const onEdit = () => {
    dispatch(edit())
  }
  const doDelete = () => {
    dispatch(deleteAPI(user))
  }

  const save = () => {
    if(user.id==="new"){
      dispatch(createAPI(useredit, userRoles));
    }else{
      dispatch(updateAPI(useredit, userRoles));
    }
  };

  useEffect(() => {
    dispatch(getRolesAPI())
    const { id } = match.params
    if(id!=="new"){
      dispatch(getAPI(id))
    }else{
      dispatch(create())
    }
  }, [dispatch, match])

  const renderUser = () => {
    if(user!==false&&!editmode&&match.params.id===user.id.toString()) return (
      <div>
        <Details user={user} edit={onEdit} toggleDelete={toggleDelete} deleting={deleting} doDelete={doDelete}/>
        <PlantsPage user={user}/>
      </div>
    )
    if(user!==false&&editmode) return <Form useredit={useredit} handleChange={handleChange} cancelEdit={onCancelEdit} save={save} roles={roles} userRoles={userRoles}/>
    return
  }

  if(isDeleted&&match.params.id===user.id.toString()){
    return (<Redirect to={"/users/"}/>)
  }

  if(isCreated){
    return (<Redirect to={"/users/"+user.id}/>)
  }

  return (
    <Box>
    <AppBar position="static">
      <Tabs value={value} onChange={handleChangeTab} aria-label="simple tabs example">
        <Tab label="Dettagli"/>
        <Tab label="Dati" disabled={user.id==='new'}/>
      </Tabs>
    </AppBar>
    {value === 0?renderUser():null}
    {value === 1?<UserDataPage user={user}/>:null}
    </Box>
  )
}

export default UserPage
