import React from 'react'
import T from 'i18n-react';
import MuiAlert from '@material-ui/lab/Alert';
import {
  Snackbar
} from "@material-ui/core";

import { useSelector, useDispatch } from 'react-redux'

import {
  notificationsSelector,
  showError,
  showSuccess,
} from './notificationsSlice'


const NotificationPage = ({ match }) => {
  const dispatch = useDispatch()

  const { error, success } = useSelector(notificationsSelector)

  const handleClose = event => {
    dispatch(showError(false))
  }
  const handleCloseSuccess = event => {
    dispatch(showSuccess(false))
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <div>
      <Snackbar open={success!==false} autoHideDuration={2000} onClose={handleCloseSuccess}>
        <Alert onClose={handleCloseSuccess} severity="success">{T.translate(success)}</Alert>
      </Snackbar>
      <Snackbar open={error!==false} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">ERRORE: {error}</Alert>
      </Snackbar>
    </div>
  )

}
export default NotificationPage
