import { createSlice } from '@reduxjs/toolkit'
import { SERVER_URL } from '../../constants';

export const initialState = {
  loading:false,
  isAuthenticated: false,
  isRefreshed: false,
  error:false,
  success:false,
  isUser:false,
  isAdmin:false,
  currentUser:false,
  config:false,
}

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    refresh: state => {
      state.loading = true
      state.isRefreshed = false
    },
    refreshSuccess: (state, { payload }) => {
      localStorage.setItem('bishop_current_user', JSON.stringify(payload))
      state.isUser = payload.roles.indexOf("ROLE_ADMIN")<0
      state.isAdmin = payload.roles.indexOf("ROLE_ADMIN")>-1
      state.loading = false
      state.isRefreshed = true
    },
    logout: (state, { payload }) => {
      localStorage.removeItem('bishop_current_user')
      state.isRefreshed = false
      state.loading = false
      state.currentUser = false
      state.isAdmin = false
      state.isUser = false
    },
    showError:  (state, { payload }) => {
      state.error = payload
    },
    showSuccess:  (state, { payload }) => {
      state.success = payload
    },
    getting: state => {
      state.currentUser = false
      state.loading = true
    },
    getSuccess: (state, { payload }) => {
      state.currentUser = payload
      state.loading = false
    },
    gettingConfig: state => {
      state.config = false
      state.loading = true
    },
    getConfigSuccess: (state, { payload }) => {
      state.config = payload
      state.loading = false
    },
    changeConfig: (state, { payload }) => {
      let c = state.config
      c[payload.name] = payload.value
      state.config = c
    },
    updatingConfig: state => {
      state.loading = true
    },
    updateConfigSuccess: (state, { payload }) => {
      state.config = payload
      state.loading = false
      state.hasErrors = false
    },
  }
})

export const { showError, showSuccess, refresh, refreshSuccess, logout, getSuccess, getting, gettingConfig, getConfigSuccess, changeConfig, updatingConfig, updateConfigSuccess } = mainSlice.actions
export const mainSelector = state => state.main
export default mainSlice.reducer


export function refreshAPI(){
  return async dispatch => {
    dispatch(refresh())
    var details = {
        'refresh_token': JSON.parse(localStorage.getItem("bishop_current_user")).refresh_token,
        'grant_type': 'refresh_token'
    };

    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const response = await fetch(SERVER_URL+'/oauth/access_token', {
      mode: 'cors',
      method: 'POST',
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body:formBody
    });

    const data = await response.json()
    if(response.status===200){
      dispatch(refreshSuccess(data))
    }else if(response.status===403){
      dispatch(logout())
    }else{
      dispatch(showError())
    }
  }
}
export function getCurrentUser() {
  let access_token = ""
  if(localStorage.getItem('bishop_current_user')!=null){
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  let username = JSON.parse(localStorage.getItem('bishop_current_user')).username
  return async dispatch => {
    dispatch(getting())
    const response = await fetch(SERVER_URL+'/api/user/username/'+username, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    const data = await response.json()
    if(response.status===200){
      dispatch(getSuccess(data))
    }else if(response.status===403){
      dispatch(logout())
    }else{
      dispatch(showError(data.message))
    }
  }
}
export function getConfig() {
  let access_token = ""
  if(localStorage.getItem('bishop_current_user')!=null){
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(gettingConfig())
    const response = await fetch(SERVER_URL+'/api/config', {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    const data = await response.json()
    if(response.status===200){
      dispatch(getConfigSuccess(data))
    }else if(response.status===403){
      dispatch(logout())
    }else{
      dispatch(showError(data.message))
    }
  }
}

export function updateConfigAPI(config) {
  let access_token = ""
  if(localStorage.getItem('bishop_current_user')!=null){
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(updatingConfig())
    let params = {config:config}
    const response = await fetch(SERVER_URL+'/api/config/'+config.id, {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    const data = await response.json()
    if(response.status===200){
      dispatch(updateConfigSuccess(data))
      dispatch(showSuccess("config.success.update"))
    }else if(response.status===403){
      dispatch(logout())
    }else{
      dispatch(showError(data.message))
    }
  }
}