import React from 'react'
import { Button, TableCell, TableRow } from '@material-ui/core'
import T from 'i18n-react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  selected:{
    backgroundColor:"#0000001a"
  }
}));

export const Item = ({ machine, select, m }) => {

  const classes = useStyles();

  return (
    <TableRow key={m.id} className={classNames({[classes.selected]: m.id===machine.id})}>
      <TableCell>{m.code}</TableCell>
      <TableCell><Button color="default" onClick={()=>select(m)}>{T.translate("button.details")}</Button></TableCell>
    </TableRow>
  )
}
