import React from 'react'
import { FormControl, InputLabel, Input, Select, MenuItem, Checkbox, FormControlLabel } from '@material-ui/core'
import T from 'i18n-react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width:"100%"
    },
  },
  buttonContainer:{
    textAlign:"right"
  }
}));

export const Form = ({ editmachine, handleChange, types }) => {
  const classes = useStyles();
  if(editmachine!==false){
    return (
        <form className={classes.root} noValidate autoComplete="off">
          <FormControl>
            <InputLabel htmlFor="code">{T.translate("machine.code")}</InputLabel>
            <Input autoFocus name="code" error={editmachine.code===""} value={editmachine.code} onChange={handleChange}/>
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="code">{T.translate("machine.type")}</InputLabel>
            <Select error={editmachine.type.id===""} name="type.id" value={editmachine.type.id} onChange={handleChange}>
              {types.map(t=>{
                return <MenuItem key={t.id} value={t.id}>{t.producer} - {t.model}</MenuItem>
              })}
            </Select>
          </FormControl>
          {editmachine.type.id.toString()!=="2"&&editmachine.type.id.toString()!=="4"?(
          <FormControl>
            <InputLabel htmlFor="volumeUnit">{T.translate("machine.volumeUnit")}</InputLabel>
            <Input autoFocus name="volumeUnit" error={editmachine.volumeUnit===""} value={editmachine.volumeUnit} onChange={handleChange}/>
          </FormControl>
          ):null}
          <FormControl>
            <InputLabel htmlFor="boxNumber">{T.translate("machine.boxNumber")}</InputLabel>
            <Input autoFocus name="boxNumber" error={editmachine.boxNumber===""} value={editmachine.boxNumber} onChange={handleChange}/>
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="ultrafabNumber">{T.translate("machine.ultrafabNumber")}</InputLabel>
            <Input autoFocus name="ultrafabNumber" error={editmachine.ultrafabNumber===""} value={editmachine.ultrafabNumber} onChange={handleChange}/>
          </FormControl>
          {editmachine.type.id.toString()==="1"?(
          <FormControl>
            <InputLabel htmlFor="uuid">{T.translate("machine.uuid")}</InputLabel>
            <Input autoFocus name="uuid" error={editmachine.code===""} value={editmachine.uuid} onChange={handleChange}/>
          </FormControl>
          ):null}
          {editmachine.type.id.toString()==="3"?(
            <React.Fragment>
              <FormControlLabel
                control={<Checkbox checked={editmachine.enableSamp1} onChange={handleChange} name="enableSamp1" color="default"/>}
                label={T.translate("machine.enableSamp1")}
              />
              <FormControlLabel
                control={<Checkbox checked={editmachine.enableSamp3} onChange={handleChange} name="enableSamp3" color="default"/>}
                label={T.translate("machine.enableSamp3")}
              />
              <FormControlLabel
                control={<Checkbox checked={editmachine.enableSamp4} onChange={handleChange} name="enableSamp4" color="default"/>}
                label={T.translate("machine.enableSamp4")}
              />
              <FormControlLabel
                control={<Checkbox checked={editmachine.enableSamp5} onChange={handleChange} name="enableSamp5" color="default"/>}
                label={T.translate("machine.enableSamp5")}
              />
            </React.Fragment>
          ):null}
        </form>
    )
  }
  return null
}
