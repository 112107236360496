import React from 'react'
import { Link } from 'react-router-dom'
import { Container, FormControl, InputLabel, Input, Divider, Button, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import T from 'i18n-react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(3),
    },
  },
  buttonContainer:{
    textAlign:"right"
  }
}));

export const Form = ({ useredit, handleChange, cancelEdit, save, roles, userRoles }) => {

  const classes = useStyles();

  return (
    <Container maxWidth={false}>
      <form className={classes.root} noValidate autoComplete="off">
      <Typography component="h1" variant="h6" color="inherit"> {T.translate("user.user")}</Typography>
        <FormControl>
          <InputLabel htmlFor="username">{T.translate("user.email")}</InputLabel>
          <Input name="username" error={useredit.username===""} value={useredit.username} onChange={handleChange}/>
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="companyName">{T.translate("user.companyName")}</InputLabel>
          <Input name="companyName" error={useredit.companyName===""} value={useredit.companyName} onChange={handleChange}/>
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="username">{T.translate("user.vatNumber")}</InputLabel>
          <Input name="vatNumber" value={useredit.vatNumber} onChange={handleChange}/>
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="phoneNumber">{T.translate("user.phoneNumber")}</InputLabel>
          <Input name="phoneNumber" value={useredit.phoneNumber} onChange={handleChange}/>
        </FormControl>
        <FormControl >
          <InputLabel htmlFor="recoveryEmail">{T.translate("user.recoveryEmail")}</InputLabel>
          <Input name="recoveryEmail" value={useredit.recoveryEmail} onChange={handleChange}/>
        </FormControl>
        <FormControl >
          <InputLabel htmlFor="referente">{T.translate("user.referente")}</InputLabel>
          <Input name="referente" value={useredit.referente} onChange={handleChange}/>
        </FormControl>
        <FormControlLabel
          control={<Checkbox checked={useredit.enabled} onChange={handleChange} name="enabled" color="default"/>}
          label={T.translate("user.enabled")}
        />
        <FormControlLabel
          control={<Checkbox checked={useredit.notifyAlert} onChange={handleChange} name="notifyAlert" color="default"/>}
          label={T.translate("user.notifyAlert")}
        />
        <FormControl >
          <InputLabel htmlFor="password">{T.translate("user.password")}</InputLabel>
          <Input name="password" type="password" value={useredit.password} onChange={handleChange} error={useredit.errorpassword}/>
        </FormControl>
        <FormControl >
          <InputLabel htmlFor="confirmPassword">{T.translate("user.confirmPassword")}</InputLabel>
          <Input name="confirmPassword" type="password" error={useredit.errorpassword} value={useredit.confirmPassword} onChange={handleChange}/>
        </FormControl>
          <div>
            <InputLabel htmlFor="roles">{T.translate("user.roles")}</InputLabel>
            {roles?(
              <div>
            {roles.map(a=>{
              return <FormControlLabel key={a.id} control={<Checkbox name="roles" value={a.authority} checked={userRoles.indexOf(a.authority)>-1} color="default" onChange={handleChange}/>} label={a.authority}/>
            })}
            </div>
          ):null}
      </div>
      </form>
      <Divider/>
      <div className={classes.buttonContainer}>
        <Button focusRipple onClick={save} disabled={useredit.errorpassword} color="primary">{T.translate("button.save")}</Button>
        {useredit.id!=="new"?
          <Button focusRipple onClick={cancelEdit}>{T.translate("button.cancel")}</Button>
          :
          <Button component={Link} to={`/users`}>{T.translate("button.back")}</Button>
        }
      </div>
      </Container>
  )
}
