import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Typography, FormControl, InputLabel, Input, Button } from '@material-ui/core';
import T from 'i18n-react';
import { mainSelector, getConfig, changeConfig, updateConfigAPI } from '../main/mainSlice'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width:"100%"
    },
  },
  buttonContainer:{
    textAlign:"right"
  },
  emails: {
    width:1000
  }
}));

const ConfigPage = () => {
  const { config } = useSelector(mainSelector)
  const dispatch = useDispatch()
  const classes = useStyles();

  useEffect(() => {
    dispatch(getConfig())
  }, [dispatch])

  const [editmode, setEditmode] = useState(false);

  const handleChange = event => {
    let name = event.target.name
    let value = event.target.value
    dispatch(changeConfig({name: name, value: value }));
  }

  const save = () => {
    setEditmode(false)
    dispatch(updateConfigAPI(config))
  };

  const onEdit = () => {
    setEditmode(true)
  }

  const renderConfig = () => {
    if(editmode){
      return (
        <form className={classes.root} noValidate autoComplete="off">
          <FormControl>
            <InputLabel htmlFor="emails">{T.translate("config.emailAlert")}</InputLabel>
            <Input name="emails" error={config.emails.indexOf("info@ultrafab.it")<0} value={config.emails} onChange={handleChange}/>
          </FormControl>
          <Button onClick={save} disabled={config.emails.indexOf("info@ultrafab.it")<0} color="primary">{T.translate("button.save")}</Button>
        </form>
          )
    }else{
      return (
        <div>
          <FormControl className={classes.emails}>
            <InputLabel htmlFor="emails">{T.translate("config.emailAlert")}</InputLabel>
            <Input name="emails" disabled={true} value={config.emails} onChange={handleChange}/>
          </FormControl>
          <Button onClick={onEdit} color="primary">{T.translate("button.edit")}</Button>
        </div>
      )
    }
  }

  if(config){
    return (
      <Container maxWidth={false}>
        <Typography component="h1" variant="h6" color="inherit"> {T.translate("config.config")}</Typography><br/>
        {renderConfig()}
      </Container>
    )
  }
  return null;
}
export default ConfigPage;
