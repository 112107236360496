import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline';
import T from 'i18n-react';
import { Link } from 'react-router-dom'
import {
  Typography,
  Paper,
  Input,
  InputLabel,
  FormControl,
  Button,
  Grid,
  Box,
  IconButton,
  InputAdornment,
  CircularProgress
} from '@material-ui/core';

import {
  Redirect,
} from 'react-router-dom'

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import {
  resetSelector,
  change,
  verifyToken,
  save,
} from './resetSlice'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(/background.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  saved: {
    textAlign:"center !important"
  }
}));

const ResetPage = ({ match }) => {
  const classes = useStyles();
  const dispatch = useDispatch()

  const { uuid } = match.params

  useEffect(() => {
    dispatch(verifyToken(uuid))
  }, [dispatch,uuid])

  const [values, setValues] = React.useState({
      showPassword: false,
    });

  const { password, errorpassword, confirmPassword, validUUID, loading, saved} = useSelector(resetSelector)

  const handleChange = event => {
    dispatch(change({ name: event.target.name, value: event.target.value }));
  };
  const onSubmit = event => {
    dispatch(save(uuid,password));
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  if (localStorage.getItem('bishop_current_user')) {
    return (
      <Redirect to={"/"} from="/reset" />
    )
  }

  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <a color="inherit" href="https://www.tdm.it/">
          TDM
        </a>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img height="50" alt="ultrafab" src="/logo.png" />
          <Typography component="h1" variant="h5">{T.translate("reset.reset")}</Typography>
          {loading?(<CircularProgress/>):
            saved?
            (<Box mt={5} className={classes.saved}>
              <Typography component="h1" variant="h5" color="primary">{T.translate("reset.resetSuccess")}</Typography>
              <br/><Link to={"/login"}>{T.translate("reset.backToLogin")}</Link>
            </Box>):
          validUUID?(
            <form className={classes.form}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">{T.translate("reset.newPassword")}</InputLabel>
                <Input name="password" type={values.showPassword ? 'text' : 'password'} value={password} onChange={handleChange} error={errorpassword}
                 endAdornment={<InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>{values.showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                    </InputAdornment>}/>
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="confirmPassword">{T.translate("reset.confirmNewPassword")}</InputLabel>
                <Input name="confirmPassword" type={values.showPassword ? 'text' : 'password'} value={confirmPassword} onChange={handleChange} error={errorpassword}
                 endAdornment={<InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>{values.showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                    </InputAdornment>}/>
              </FormControl>
              <Button fullWidth type="button" onClick={onSubmit} variant="contained" color="primary" className={classes.submit}>{T.translate("button.save")}</Button>
            </form>
          ):
            <Box mt={5}>
              <Typography component="h1" variant="h5" color="secondary">{T.translate("reset.invalidUUID")}</Typography>
              <br/><Link to={"/forgot"}>{T.translate("reset.requestAgain")}</Link>
            </Box>
            }
          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
  )
}
export default ResetPage
