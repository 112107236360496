import moment from 'moment';
import momentIT from 'moment/locale/it';
moment.locale('it', momentIT);
moment.updateLocale('it', {
  longDateFormat: {
    LTS: 'DD/MM/YYYY HH:mm:ss',
    L: 'DD/MM/YYYY',
  },
});
export const bishopMoment = moment;
export const SERVER_URL =
  process.env.NODE_ENV === 'production'
    ? `https://${window.location.hostname}:8443/bishop`
    : `http://${window.location.hostname}:8080`;
export const GRAFANA_URL =
  process.env.NODE_ENV === 'production'
    ? `https://server.tdm.it:3000`
    : `https://server.tdm.it:3000`;
