import React from 'react'
import MainPage from './features/main/MainPage'
import LoginPage from './features/login/LoginPage'
import ResetPage from './features/reset/ResetPage'
import ForgotPage from './features/reset/ForgotPage'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/forgot" component={ForgotPage} />
        <Route exact path="/reset/:uuid" component={ResetPage} />
        <Route path="/" component={MainPage} />
      </Switch>
    </Router>
  )
}

export default App
