import React from 'react'
import { Typography, Container, Table, TableCell, TableRow, TableBody, Divider, Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@material-ui/core'
import T from 'i18n-react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width:"100%",
    paddingTop:theme.spacing(2)
  },
  buttonContainer:{
    textAlign:"right",
  }
}));

export const Details = ({ plant, edit, toggleDelete, deleting, doDelete }) => {

  const classes = useStyles();

  return (
    <Container maxWidth={false} className={classes.root}>
        <Typography component="h1" variant="h6" color="inherit"> {T.translate("plant.plant")}</Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{T.translate("plant.name")}</TableCell>
              <TableCell>{plant.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{T.translate("plant.address")}</TableCell>
              <TableCell>{plant.address}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      <Divider/>
      <div className={classes.buttonContainer}>
        <Button focusRipple key={plant.id} onClick={edit} color="primary">{T.translate("button.edit")}</Button>
        <Button color="secondary" onClick={toggleDelete}>{T.translate("button.delete")}</Button>
      </div>
      <Dialog
        open={deleting}
        keepMounted
        onClose={toggleDelete}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{T.translate("function.confirm")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{T.translate("function.confirmDelete")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={doDelete} color="secondary">{T.translate("button.confirm")}</Button>
          <Button onClick={toggleDelete} color="default">{T.translate("button.cancel")}</Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}
