import React from 'react'
import { Link } from 'react-router-dom'
import { Container, FormControl, InputLabel, Input, Divider, Button, Typography } from '@material-ui/core'
import T from 'i18n-react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(3),
    },
  },
  buttonContainer:{
    textAlign:"right"
  }
}));

export const Form = ({ machineTypeedit, handleChange, cancelEdit, save, roles, machineTypeRoles }) => {

  const classes = useStyles();

  return (
    <Container maxWidth={false}>
      <form className={classes.root} noValidate autoComplete="off">
      <Typography component="h1" variant="h6" color="inherit"> {T.translate("machineType.machineType")}</Typography>
        <FormControl>
          <InputLabel htmlFor="producer">{T.translate("machineType.producer")}</InputLabel>
          <Input name="producer" error={machineTypeedit.producer===""} value={machineTypeedit.producer} onChange={handleChange}/>
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="model">{T.translate("machineType.model")}</InputLabel>
          <Input name="model" error={machineTypeedit.model===""} value={machineTypeedit.model} onChange={handleChange}/>
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="description">{T.translate("machineType.description")}</InputLabel>
          <Input name="description" value={machineTypeedit.description} onChange={handleChange}/>
        </FormControl>
      </form>
      <Divider/>
      <div className={classes.buttonContainer}>
        <Button focusRipple onClick={save} disabled={machineTypeedit.errorpassword} color="primary">{T.translate("button.save")}</Button>
        {machineTypeedit.id!=="new"?
          <Button focusRipple onClick={cancelEdit}>{T.translate("button.cancel")}</Button>
          :
          <Button component={Link} to={`/machineTypes`}>{T.translate("button.back")}</Button>
        }
      </div>
      </Container>
  )
}
