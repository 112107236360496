import React from 'react';
import { Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Iframe from 'react-iframe'
import { GRAFANA_URL } from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2)
  },
  buttonContainer:{
    textAlign:"right"
  }
}));

const UserDataPage = ({user}) => {
  const classes = useStyles();
  return(
        <div>
        {user.plants.map(p=>{
          return (
            <div key={p.id}>
              {p.machines.map(m=>{
                return (
                  <Paper key={m.id} className={classes.root}>
                    <Typography component="h1" variant="h6" color="inherit">{p.name} - {m.type.producer}/{m.type.model}</Typography>
                    {m.type.producer==="PACKO"?(
                      <Iframe frameBorder="0" url={GRAFANA_URL+"/d/DP_eoLVGk/packo-tank?orgId=1&kiosk=tv&theme=light&from=now-7d&var-volume_um="+m.volumeUnit+"&to=now&var-uuid_t="+m.uuid+"&var-uuid_v="+m.uuid+"&var-uuid_e="+m.uuid}
                              width="100%" height="800px" display="initial" position="relative"/>
                            ):null}
                    {m.type.producer==="FRM"?(
                      <Iframe frameBorder="0" url={GRAFANA_URL+"/d/eK0yTLVGz/frm?orgId=1&from=now-7d&to=now&var-uuid="+m.uuid+"&theme=light&kiosk=tv"}
                              width="100%" height="800px" display="initial" position="relative"/>
                            ):null}
                    {m.type.model==="AD 4.0"?(
                      <React.Fragment>
                        {m.enableSamp1?(
                          <Iframe frameBorder="0" url={GRAFANA_URL+"/d/d4wnxdq7z/ad-4-0-sala-di-mungitura?orgId=1&kiosk=tv&theme=light&from=now-7d&var-volume_um="+m.volumeUnit+"&to=now&var-uuid="+m.uuid}
                          width="100%" height="800px" display="initial" position="relative"/>
                        ):null}
                        <Iframe frameBorder="0" url={GRAFANA_URL+"/d/KbADYLBMz/ad-4-0-tank?orgId=1&kiosk=tv&theme=light&from=now-7d&var-volume_um="+m.volumeUnit+"&to=now&var-uuid="+m.uuid}
                                width="100%" height="800px" display="initial" position="relative"/>
                        {m.enableSamp3?(
                          <Iframe frameBorder="0" url={GRAFANA_URL+"/d/o8pnBOq7z/ad-4-0-tank-acqua?orgId=1&kiosk=tv&theme=light&from=now-7d&var-volume_um="+m.volumeUnit+"&to=now&var-uuid="+m.uuid}
                          width="100%" height="800px" display="initial" position="relative"/>
                        ):null}
                        {m.enableSamp4?(
                          <Iframe frameBorder="0" url={GRAFANA_URL+"/d/cybaQN_Vk/ad-4-0-tank-2?orgId=1&kiosk=tv&theme=light&from=now-7d&var-volume_um="+m.volumeUnit+"&to=now&var-uuid="+m.uuid}
                          width="100%" height="800px" display="initial" position="relative"/>
                        ):null}
                        {m.enableSamp5?(
                          <Iframe frameBorder="0" url={GRAFANA_URL+"/d/KzqM_HlVz/ad-4-0-tank-3?orgId=1&kiosk=tv&theme=light&from=now-7d&var-volume_um="+m.volumeUnit+"&to=now&var-uuid="+m.uuid}
                          width="100%" height="800px" display="initial" position="relative"/>
                        ):null}
                      </React.Fragment>
                        ):null}
                    {m.type.producer==="URBAN"?(
                      <Iframe frameBorder="0" url={GRAFANA_URL+"/d/nJavnLQMk/urban?orgId=1&from=now-7d&to=now&var-uuid="+m.uuid+"&theme=light&kiosk=tv"}
                              width="100%" height="800px" display="initial" position="relative"/>
                            ):null}
                  </Paper>
                )
              })}
              </div>
            )
        })}
        </div>
  )
}
export default UserDataPage;
