import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import T from 'i18n-react';
import { machinesSelector, getAPI, create, edit, cancelEdit, change, createAPI, updateAPI, select, deleteAPI, confirmDelete } from './machinesSlice'
import { machineTypesSelector, getAPI as getTypesAPI } from '../machineTypes/machineTypesSlice'
import { makeStyles } from '@material-ui/core/styles';

import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
} from "@material-ui/core";

import { Form } from './Form'
import { Item } from './Item'
import { Details } from './Details'

const useStyles = makeStyles((theme) => ({
  root: {
      paddingLeft: theme.spacing(2)
  },
  buttonContainer:{
    textAlign:"right"
  }
}));

export const  MachinesPage = ({user, plant}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { machines, editmachine, machine, editmode, deleting, isChanged } = useSelector(machinesSelector)
  const { machineTypes } = useSelector(machineTypesSelector)
  useEffect(() => {
    dispatch(getTypesAPI())
    dispatch(getAPI(user,plant))
  }, [dispatch, user, plant])

  if(isChanged){
    dispatch(getAPI(user,plant))
  }

  const handleChange = event => {
    let name = event.target.name
    let value = event.target.value
    if(event.target.type==="checkbox"){
      value = event.target.checked
    }
    dispatch(change({name: name, value: value }));
  }

  const save = () => {
    if(editmachine.id==="new"){
      dispatch(createAPI(user, plant, editmachine));
    }else{
      dispatch(updateAPI(user, plant, editmachine));
    }
  };

  const onSelect = (machine) => {
    dispatch(select(machine))
  }

  const onEdit = () => {
    dispatch(edit())
  }

  const toggleDelete = () => {
    dispatch(confirmDelete())
  }

  const doDelete = () => {
    dispatch(deleteAPI(user, plant, machine))
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={8}>
        <Grid item xs={4}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><Typography component="h1" variant="h6" color="inherit"> {T.translate("machine.machines")}</Typography></TableCell>
                <TableCell><Button color="primary" onClick={()=>dispatch(create())}>{T.translate("button.add")}</Button></TableCell>
              </TableRow>
            </TableHead>
            {machines?(
              <TableBody>
                  {machines.map(m=>{
                    return(
                      <Item key={m.id} machine={machine} m={m} select={onSelect}/>
                    )
                  })}
              </TableBody>
            ):null}
          </Table>
        </Grid>
        <Grid item xs={8}>
          {machine?
              <Details machine={machine} edit={onEdit} toggleDelete={toggleDelete} deleting={deleting} doDelete={doDelete}/>
          :null}
        </Grid>
      </Grid>
      <Dialog
        open={editmode}
        keepMounted
        maxWidth={"sm"}
        fullWidth={true}
        onClose={()=>dispatch(cancelEdit())}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{T.translate("machine.machine")}</DialogTitle>
          <DialogContent>
            <Form editmachine={editmachine} handleChange={handleChange} types={machineTypes}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={save} color="primary">{T.translate("button.save")}</Button>
            <Button onClick={()=>dispatch(cancelEdit())} color="default">{T.translate("button.cancel")}</Button>
          </DialogActions>
        </Dialog>
    </div>
  )
}
