import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline';
import T from 'i18n-react';
import { Link } from 'react-router-dom'
import {
  Typography,
  Paper,
  Input,
  InputLabel,
  FormControl,
  Button,
  Grid,
  Box,
  CircularProgress
} from '@material-ui/core';

import NotificationsPage from '../notifications/NotificationsPage'

import {
  resetSelector,
  change,
  sendEmailAPI,
} from './resetSlice'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(background.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  forgot: {
    textAlign:"center !important"
  }
}));

export default function LoginPage() {
  const classes = useStyles();
  const dispatch = useDispatch()
  const { email, user, loading } = useSelector(resetSelector)

  const handleChange = event => {
    dispatch(change({ name: event.target.name, value: event.target.value }));
  };
  const onSubmit = event => {
    dispatch(sendEmailAPI(email));
  };

  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <a color="inherit" href="https://www.tdm.it/">
          TDM
        </a>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <img height="50" alt="ultrafab" src="logo.png" />
            <Typography component="h1" variant="h5">{T.translate("reset.reset")}</Typography>
            {loading?(<CircularProgress/>):
            user?(
              <div>
              <Box mt={5}>
                <Typography component="h3" variant="h6">{T.translate("reset.thanks")} {user.companyName}, {T.translate("reset.checkEmail")} {user.recoveryEmail} {T.translate("reset.click")}</Typography>
              </Box>
              <Box mt={1} className={classes.forgot}>
                <Link to={"/login"}>{T.translate("reset.backToLogin")}</Link>
              </Box>
              </div>
            ):(
            <form className={classes.form}>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="email">{T.translate("reset.email")}</InputLabel>
                  <Input name="email" value={email} autoFocus onChange={handleChange} />
                </FormControl>
                <Button fullWidth type="button" onClick={onSubmit} variant="contained" color="primary" className={classes.submit}>{T.translate("reset.sendLink")}</Button>
                <Box mt={1} className={classes.forgot}>
                  <Link to={"/login"}>{T.translate("reset.backToLogin")}</Link>
                </Box>
                <Box mt={5}>
                  <Copyright />
                </Box>
            </form>
            )}
          </div>
      </Grid>
      <NotificationsPage/>
    </Grid>
  )
}
