import { createSlice } from '@reduxjs/toolkit'
import { SERVER_URL } from '../../constants';
import { showError } from '../notifications/notificationsSlice';
import { logout } from '../main/mainSlice';
import { reset } from './userSlice';

export const initialState = {
  loading: false,
  hasErrors: false,
  users: [],
  reload: false,
  results: 0,
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getting: state => {
      state.loading = true
      state.reload = false;
    },
    getSuccess: (state, { payload }) => {
      state.users = payload[1];
      state.results = payload[0].total_entries;
      state.loading = false
      state.hasErrors = false
    },
    setReload: state => {
      state.reload = true;
    }
  }
})

export const { getting, getSuccess, setReload } = usersSlice.actions
export const usersSelector = state => state.users
export default usersSlice.reducer

export function getAPI(page, rowsPerPage, orderBy, order, companyName, machineType) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(getting());
    dispatch(reset());

    let url = SERVER_URL + "/api/user?page=" + page + "&per_page=" + rowsPerPage + "&orderBy=" + orderBy + "&order=" + order;
    if (companyName !== false) {
      url = url + "&companyName=" + companyName;
    }
    if (machineType !== false) {
      url = url + "&machineType=" + machineType.id;
    }
      const response = await fetch(url, {
        mode: 'cors',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'Authorization': 'Bearer ' + access_token
        }
      });

      const data = await response.json()
      if(response.status===200){
        dispatch(getSuccess(data))
      }else if(response.status===403){
        dispatch(logout())
      }else{
        dispatch(showError(data.message))
      }
  }
}
