import React from 'react'
import { Link } from 'react-router-dom'
import { Typography, Container, FormControl, InputLabel, Input, Divider, Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@material-ui/core'
import T from 'i18n-react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(3),
    },
  },
  buttonContainer:{
    textAlign:"right"
  }
}));

export const Details = ({ machineType, edit, toggleDelete, deleting, doDelete }) => {

  const classes = useStyles();

  return (
    <Container maxWidth={false}>
      <form className={classes.root} noValidate autoComplete="off">
        <Typography component="h1" variant="h6" color="inherit"> {T.translate("machineType.machineType")}</Typography>
          <FormControl disabled>
            <InputLabel htmlFor="producer">{T.translate("machineType.producer")}</InputLabel>
            <Input id="producer" value={machineType.producer} />
          </FormControl>
          <FormControl disabled>
            <InputLabel htmlFor="model">{T.translate("machineType.model")}</InputLabel>
            <Input value={machineType.model} />
          </FormControl>
          <FormControl disabled>
            <InputLabel htmlFor="description">{T.translate("machineType.description")}</InputLabel>
            <Input value={machineType.description} />
          </FormControl>
        </form>
      <Divider/>
      <div className={classes.buttonContainer}>
        <Button focusRipple key={machineType.id} onClick={edit} color="primary">{T.translate("button.edit")}</Button>
        <Button color="secondary" onClick={toggleDelete}>{T.translate("button.delete")}</Button>
        <Button component={Link} to={`/machineTypes`}>{T.translate("button.back")}</Button>
      </div>
      <Dialog
        open={deleting}
        keepMounted
        onClose={toggleDelete}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{T.translate("function.confirm")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{T.translate("function.confirmDelete")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={doDelete} color="secondary">{T.translate("button.confirm")}</Button>
          <Button onClick={toggleDelete} color="default">{T.translate("button.cancel")}</Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}
