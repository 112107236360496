import React from 'react'
import { FormControl, InputLabel, Input } from '@material-ui/core'
import T from 'i18n-react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width:"100%"
    },
  },
  buttonContainer:{
    textAlign:"right"
  }
}));

export const Form = ({ editplant, handleChange }) => {

  const classes = useStyles();
  if(editplant!==false){
    return (
        <form className={classes.root} noValidate autoComplete="off">
          <FormControl>
            <InputLabel htmlFor="name">{T.translate("plant.name")}</InputLabel>
            <Input autoFocus name="name" error={editplant.name===""} value={editplant.name} onChange={handleChange}/>
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="address">{T.translate("plant.address")}</InputLabel>
            <Input name="address" value={editplant.address} onChange={handleChange}/>
          </FormControl>
        </form>
    )
  }
  return null
}
