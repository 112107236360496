import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import DvrIcon from '@material-ui/icons/Dvr';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom'
import T from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux'
import classNames from "classnames";
import { makeStyles } from '@material-ui/core/styles';

import {
  mainSelector,
  logout
} from '../main/mainSlice'

const useStyles = makeStyles((theme) => ({
  selected:{
    backgroundColor:"#0000001a"
  },
  logout:{
    paddingLeft:theme.spacing(2)
  }
}));

const MenuPage = ({match}) => {
  const dispatch = useDispatch()
  const { isAdmin } = useSelector(mainSelector)
  const classes = useStyles();

  const [current,setCurrent] = React.useState(window.location.pathname);
  const change = (url) => {
    setCurrent(url);
  };

  return (
    <div>
      {isAdmin?(
        <div>
        <ListItem button component={Link} onClick={()=>change("/users")} to="/users" className={classNames({[classes.selected]: current.indexOf("/users")>-1})}>
          <ListItemIcon>
            <PeopleIcon/>
          </ListItemIcon>
          <ListItemText primary={T.translate("user.users")} />
        </ListItem>
        <ListItem button component={Link} onClick={()=>change("/machineTypes")} to="/machineTypes" className={classNames({[classes.selected]: current.indexOf("/machineTypes")>-1})}>
          <ListItemIcon>
            <DvrIcon/>
          </ListItemIcon>
          <ListItemText primary={T.translate("machineType.machineTypes")} />
        </ListItem>
        <ListItem button component={Link} onClick={()=>change("/config")} to="/config" className={classNames({[classes.selected]: current==="/config"})}>
          <ListItemIcon>
            <SettingsIcon/>
          </ListItemIcon>
          <ListItemText primary={T.translate("config.config")} />
        </ListItem>
        </div>
      ):null}
      <ListItem className={classes.logout} button component={Button} onClick={()=>dispatch(logout())}>
        <ListItemIcon>
          <ExitToAppIcon/>
        </ListItemIcon>
        <ListItemText primary={T.translate("button.logOut")} />
      </ListItem>
    </div>
  );
}
export default MenuPage;
