import React from 'react'
import { Link } from 'react-router-dom'
import { TableCell, TableRow, Button } from '@material-ui/core'
import T from 'i18n-react';

export const Item = ({ machineType }) => (
    <TableRow>
        <TableCell>{machineType.producer}</TableCell>
        <TableCell>{machineType.model}</TableCell>
        <TableCell>{machineType.description}</TableCell>
        <TableCell>
            <Button component={Link} to={`/machineTypes/${machineType.id}`}>{T.translate("button.details")}</Button>
        </TableCell>
    </TableRow>
)
