import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  error:false,
  success:false,
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    showError:  (state, { payload }) => {
      state.error = payload
    },
    showSuccess:  (state, { payload }) => {
      state.success = payload
    },
  }
})

export const { showError, showSuccess } = notificationsSlice.actions
export const notificationsSelector = state => state.notifications
export default notificationsSlice.reducer
