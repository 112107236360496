import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline';
import T from 'i18n-react';
import { Link } from 'react-router-dom'
import {
  Typography,
  Paper,
  Input,
  InputLabel,
  FormControl,
  Button,
  Grid,
  Box,
} from '@material-ui/core';

import {
  Redirect,
} from 'react-router-dom'

import {
  loginSelector,
  change,
  loginAPI,
} from './loginSlice'
import {
  reset,
} from '../reset/resetSlice'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(/background.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  forgot: {
    textAlign:"center !important"
  }
}));

export default function LoginPage() {
  const classes = useStyles();
  const dispatch = useDispatch()
  const { user } = useSelector(loginSelector)

  const handleChange = event => {
    dispatch(change({ name: event.target.name, value: event.target.value }));
  };
  const onSubmit = event => {
    dispatch(loginAPI(user));
  };

  if (localStorage.getItem('bishop_current_user')) {
    return (
      <Redirect to={"/"} from="/login" />
    )
  }

  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <a color="inherit" href="https://www.tdm.it/">
          TDM
        </a>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img height="50" alt="ultrafab" src="logo.png" />
          <Typography component="h1" variant="h5">{T.translate("button.logIn")}</Typography>
          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">{T.translate("user.email")}</InputLabel>
              <Input name="username" value={user.username} autoFocus onChange={handleChange} />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">{T.translate("user.password")}</InputLabel>
              <Input name="password" value={user.password} type="password" id="password" onChange={handleChange} />
            </FormControl>
            <Button fullWidth type="button" onClick={onSubmit} variant="contained" color="primary" className={classes.submit}>{T.translate("button.signIn")}</Button>

            <Box mt={1} className={classes.forgot}>
              <Link onClick={()=>dispatch(reset())} to={"/forgot"}>{T.translate("button.forgotPassword")}</Link>
            </Box>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  )
}
