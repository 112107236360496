import { createSlice } from '@reduxjs/toolkit'
import { showError, showSuccess } from '../notifications/notificationsSlice';
import { logout } from '../main/mainSlice';
import { SERVER_URL } from '../../constants';
import { reset as resetMachines } from '../machines/machinesSlice';
import { reset as resetPlants } from '../plants/plantsSlice';

export const initialState = {
  loading: false,
  hasErrors: false,
  editmode: false,
  user: false,
  useredit:false,
  deleting:false,
  isCreated:false,
  isDeleted:false,
  userRoles:false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: state => {
      state.user = false
      state.useredit = false
      state.editmode = false
      state.isDeleted = false
      state.isCreated = false
      state.userRoles = false
    },
    getting: state => {
      state.user = false
      state.isCreated = false
      state.isDeleted = false
      state.loading = true
      state.editmode = false
    },
    getSuccess: (state, { payload }) => {
      payload.confirmPassword = payload.password
      state.user = payload
      state.userRoles = payload.authorities
      state.loading = false
      state.hasErrors = false
    },
    new: state => {
      state.user = {username:""}
    },
    creating: state => {
      state.loading = true
    },
    create: state => {
      let newuser = {"id":"new","username":"","companyName":"","phoneNumber":"","vatNumber":"","recoveryEmail":"","enabled":false,"password":"","confirmPassword":"","referente":"","notifyAlert":false}
      state.userRoles = []
      state.user = newuser
      state.useredit = newuser
      state.editmode = true
    },
    createSuccess: (state, { payload }) => {
      state.user = payload
      state.useredit = false
      state.loading = false
      state.hasErrors = false
      state.isCreated = true
    },
    resetEdit: state => {
      state.editmode = true
      state.useredit = state.user
    },
    edit: state => {
      state.editmode = true
      state.useredit = state.user
    },
    cancelEdit: state => {
      state.editmode = false
      state.useredit = false
    },
    change: (state, { payload }) => {
      let u = state.useredit
      u[payload.name] = payload.value
      let errorpassword = false
      if(u.id==="new"){
        errorpassword = state.useredit.password!==state.useredit.confirmPassword || state.useredit.password===""
      }else{
        errorpassword = state.useredit.password!==state.useredit.confirmPassword&&state.useredit.password!==""
      }
      u.errorpassword = errorpassword
      state.useredit = u
    },
    updating: state => {
      state.loading = true
    },
    updateSuccess: (state, { payload }) => {
      state.user = payload
      state.loading = false
      state.hasErrors = false
      state.editmode = false
    },
    confirmDelete: state => {
      state.deleting = !state.deleting
    },
    deleting: state => {
      state.deleting = false
      state.loading = true
      state.hasErrors = false
    },
    deleteSuccess: state => {
      state.loading = false
      state.hasErrors = false
      state.isDeleted = true
    },
    loadingRoles: state => {
      state.roles = false
      state.hasErrors = false
      state.loading = true
    },
    loadingRolesSuccess: (state, { payload }) => {
      state.roles = payload
      state.loading = false
      state.hasErrors = false
    },
    changeRoles: (state, { payload }) => {
      let ur = state.userRoles
      if(payload.value){
        ur.push(payload.role)
      }else{
        let indexOfRole = ur.findIndex(r => {
          return r === payload.role;
        });
        ur = [...ur.filter((_, i) => i !== indexOfRole)]
      }
      state.userRoles = ur
    }
  }
})

export const {
  reset, getting, getSuccess, create, creating, createSuccess,
  edit, cancelEdit, change , updating, updateSuccess,
  confirmDelete,deleting,deleteSuccess,resetEdit,
  loadingRoles, loadingRolesSuccess, changeRoles,
 } = userSlice.actions

export const userSelector = state => state.user

export default userSlice.reducer

export function updateAPI(user, userRoles) {
  let access_token = ""
  if(localStorage.getItem('bishop_current_user')!=null){
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  let params = {user:user,roles:userRoles}
  return async dispatch => {
    dispatch(getting())
    const response = await fetch(SERVER_URL+'/api/user/'+user.id, {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body:JSON.stringify(params)
    });

    const data = await response.json()
    if(response.status===200){
      dispatch(updateSuccess(data))
      dispatch(showSuccess("user.success.update"))
    }else if(response.status===403){
      dispatch(logout())
    }else{
      dispatch(resetEdit())
      dispatch(showError(data.error))
    }
  }
}
export function createAPI(user,userRoles) {
  let access_token = ""
  if(localStorage.getItem('bishop_current_user')!=null){
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(creating())
      let params = {user:user,roles:userRoles}
      const response = await fetch(SERVER_URL+'/api/user', {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'Authorization': 'Bearer ' + access_token
        },
        body: JSON.stringify(params)
      });

      const data = await response.json()
      if(response.status===200){
        dispatch(createSuccess(data))
        dispatch(showSuccess("user.success.create"))
      }else if(response.status===403){
        dispatch(logout())
      }else{
        dispatch(showError(data.message))
      }
  }
}

export function getAPI(id) {
  let access_token = ""
  if(localStorage.getItem('bishop_current_user')!=null){
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(getting())
    dispatch(resetMachines())
    dispatch(resetPlants())
    const response = await fetch(SERVER_URL+'/api/user/'+id, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    const data = await response.json()
    if(response.status===200){
      dispatch(getSuccess(data))
    }else if(response.status===403){
      dispatch(logout())
    }else{
      dispatch(showError(data.message))
    }
  }
}
export function deleteAPI(user) {
  let access_token = ""
  if(localStorage.getItem('bishop_current_user')!=null){
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(deleting())
    const response = await fetch(SERVER_URL+'/api/user/'+user.id, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    const data = await response.json()
    if(response.status===200){
      dispatch(deleteSuccess(data))
      dispatch(showSuccess("user.success.delete"))
    }else if(response.status===403){
      dispatch(logout())
    }else{
      dispatch(showError(data.message))
    }
  }
}
export function getRolesAPI() {
  let access_token = ""
  if(localStorage.getItem('bishop_current_user')!=null){
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(loadingRoles())
    const response = await fetch(SERVER_URL+'/api/role', {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    const data = await response.json()
    if(response.status===200){
      dispatch(loadingRolesSuccess(data))
    }else if(response.status===403){
      dispatch(logout())
    }else{
      dispatch(showError(data.message))
    }
  }
}
