import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import T from 'i18n-react';
import { Link } from 'react-router-dom'
import { machineTypesSelector, getAPI } from './machineTypesSlice'

import { Item } from './Item'

import {
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";

const MachineTypesPage = () => {
  const dispatch = useDispatch()
  const { machineTypes, loading } = useSelector(machineTypesSelector)

  useEffect(() => {
    dispatch(getAPI())
  }, [dispatch])

  const renderMachineTypes = () => {
    if (loading) return <TableRow><TableCell>{T.translate("function.loading")}</TableCell></TableRow>
    if(machineTypes!==false){ return machineTypes.map(machineType => <Item key={machineType.id} machineType={machineType} />) }
  }

  return (
    <Box>
      <Typography component="h1" variant="h6" color="inherit"> {T.translate("machineType.machineTypes")}</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{T.translate("machineType.producer")}</TableCell>
            <TableCell>{T.translate("machineType.model")}</TableCell>
            <TableCell>{T.translate("machineType.description")}</TableCell>
            <TableCell><Button component={Link} color="primary" to={`/machineTypes/new`}>{T.translate("button.add")}</Button></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            renderMachineTypes()
          }
        </TableBody>
      </Table>
    </Box>
  )
}

export default MachineTypesPage
