import React from 'react'
import { Button, TableCell, TableRow } from '@material-ui/core'
import T from 'i18n-react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  selected:{
    backgroundColor:"#0000001a"
  }
}));

export const Item = ({ plant, select, p }) => {

  const classes = useStyles();

  return (
    <TableRow key={p.id} className={classNames({[classes.selected]: p.id===plant.id})}>
      <TableCell>{p.name}</TableCell>
      <TableCell><Button color="default" onClick={()=>select(p)}>{T.translate("button.details")}</Button></TableCell>
    </TableRow>
  )
}
