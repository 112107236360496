import { createSlice } from '@reduxjs/toolkit'
import { SERVER_URL } from '../../constants';
import { showError } from '../notifications/notificationsSlice';

export const initialState = {
  loading: false,
  hasErrors: false,
  email:'',
  user:false,
  validUUID:false,
  saved:false,
  password:'',
  confirmPassword:'',
  errorpassword:false
}

const resetSlice = createSlice({
  name: 'reset',
  initialState,
  reducers: {
    change: (state, { payload }) => {
      state[payload.name] = payload.value
      state.errorpassword = state.password!==state.confirmPassword&&state.password!==""
    },
    sending: state => {
      state.loading = true
    },
    sendingSuccess: (state, { payload }) => {
      state.user = payload
      state.loading = false
    },
    verifying: state => {
      state.loading = true
      state.saved = false
      state.password = ''
      state.confirmPassword = ''
    },
    verifyingSuccess: (state, { payload }) => {
      state.loading = false
      state.validUUID = true
    },
    verifyingError: state => {
      state.validUUID = false
      state.loading = false
    },
    reset: state => {
      state.user = false
      state.email = ""
    },
    saving: state => {
      state.loading = true
    },
    savingSuccess: state => {
      state.loading = false
      state.saved = true
    }
  }
})

export const { reset, change, sending, sendingSuccess, verifying, verifyingSuccess, verifyingError, saving, savingSuccess } = resetSlice.actions
export const resetSelector = state => state.reset
export default resetSlice.reducer

export function sendEmailAPI(email) {
  return async dispatch => {
    dispatch(sending())
      let params = {email:email}
      const response = await fetch(SERVER_URL+'/user/sendEmail', {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(params)
      });
      const data = await response.json()
      if(response.status===200){
        dispatch(sendingSuccess(data))
      }else{
        dispatch(showError(data.message))
      }
  }
}
export function verifyToken(uuid) {
  return async dispatch => {
    dispatch(verifying())
      const response = await fetch(SERVER_URL+'/user/verifyUUID/'+uuid, {
        mode: 'cors',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        }
      });
      if(response.status===200){
        dispatch(verifyingSuccess())
      }else{
        dispatch(verifyingError())
      }
  }
}
export function save(uuid, password) {
  return async dispatch => {
    dispatch(saving())
      let params = {uuid:uuid, password:password}
      const response = await fetch(SERVER_URL+'/user/resetPassword', {
        mode: 'cors',
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(params)
      });
      const data = await response.json()
      if(response.status===200){
        dispatch(savingSuccess(data))
      }else{
        dispatch(showError(data.message))
      }
  }
}
