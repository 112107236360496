/* eslint no-eval: 0 */
import { createSlice } from '@reduxjs/toolkit'
import { SERVER_URL } from '../../constants';
import { showError, showSuccess } from '../notifications/notificationsSlice';
import { logout } from '../main/mainSlice';

export const initialState = {
  loading: false,
  hasErrors: false,
  machines: [],
  editmode: false,
  editmachine: false,
  machine: false,
  deleting: false,
  isChanged: false,
}

const machinesSlice = createSlice({
  name: 'machines',
  initialState,
  reducers: {
    reset: state => {
      state.machines = false
      state.machine = false
      state.editmachine = false
      state.editmode = false
    },
    getting: state => {
      state.loading = true
      state.isChanged = false
      state.editmachine = false
    },
    getSuccess: (state, { payload }) => {
      state.machines = payload
      state.loading = false
      state.hasErrors = false
    },
    edit: state => {
      state.editmode = true
      state.editmachine = state.machine
    },
    cancelEdit: state => {
      state.editmachine = false
      state.machine = false
      state.editmode = false
    },
    create: state => {
      state.editmode = true
      state.editmachine = {"id":"new","code":"","type":{"id":''},"boxNumber":"","ultrafabNumber":"","volumeUnit":"","enableSamp1":false,"enableSamp3":false,"enableSamp4":false,"enableSamp5":false}
    },
    change: (state, { payload }) => {
      let p = state.editmachine
      if(payload.name.indexOf(".")>-1){
        eval("p."+payload.name+"='"+payload.value+"'");
      }else{
        p[payload.name] = payload.value
      }
      state.editmachine = p
    },
    creating: state => {
      state.loading = true
    },
    createSuccess: (state, { payload }) => {
      state.machine = payload
      state.isChanged = true
      state.editmode = false
      state.editmachine = false
      state.loading = false
      state.hasErrors = false
    },
    updating: state => {
      state.loading = true
    },
    updateSuccess: (state, { payload }) => {
      state.machine = payload
      state.isChanged = true
      state.loading = false
      state.hasErrors = false
      state.editmode = false
    },
    select: (state, {payload}) => {
      state.machine = payload
    },
    confirmDelete: state => {
      state.deleting = !state.deleting
    },
    deleting: state => {
      state.deleting = false
      state.loading = true
      state.hasErrors = false
    },
    deleteSuccess: state => {
      state.loading = false
      state.hasErrors = false
      state.machine = false
      state.isChanged = true
    },
  }
})

export const { reset, getting, getSuccess, create, createSuccess, creating, edit, cancelEdit, change, updating, updateSuccess, select, confirmDelete, deleting, deleteSuccess } = machinesSlice.actions
export const machinesSelector = state => state.machines
export default machinesSlice.reducer

export function getAPI(user,plant) {
  let access_token = ""
  if(localStorage.getItem('bishop_current_user')!=null){
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
      dispatch(getting())
      const response = await fetch(SERVER_URL+'/api/user/'+user.id+'/plant/'+plant.id+'/machine', {
        mode: 'cors',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'Authorization': 'Bearer ' + access_token
        }
      });

      const data = await response.json()
      if(response.status===200){
        dispatch(getSuccess(data))
      }else if(response.status===403){
        dispatch(logout())
      }else{
        dispatch(showError(data.message))
      }
  }
}

export function createAPI(user,plant,machine) {
  let access_token = ""
  if(localStorage.getItem('bishop_current_user')!=null){
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(creating())
    let params = {machine:machine}
    const response = await fetch(SERVER_URL+'/api/user/'+user.id+'/plant/'+plant.id+'/machine', {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    const data = await response.json()
    if(response.status===200){
      dispatch(createSuccess(data))
      dispatch(showSuccess("machine.success.create"))
    }else if(response.status===403){
      dispatch(logout())
    }else{
      dispatch(showError(data.message))
    }
  }
}

export function updateAPI(user,plant,machine) {
  let access_token = ""
  if(localStorage.getItem('bishop_current_user')!=null){
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(updating())
    let params = {machine:machine}
    const response = await fetch(SERVER_URL+'/api/user/'+user.id+'/plant/'+plant.id+'/machine/'+machine.id, {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    const data = await response.json()
    if(response.status===200){
      dispatch(updateSuccess(data))
      dispatch(showSuccess("machine.success.update"))
    }else if(response.status===403){
      dispatch(logout())
    }else{
      dispatch(showError(data.message))
    }
  }
}

export function deleteAPI(user,plant,machine) {
  let access_token = ""
  if(localStorage.getItem('bishop_current_user')!=null){
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(deleting())
    const response = await fetch(SERVER_URL+'/api/user/'+user.id+'/plant/'+plant.id+'/machine/'+machine.id, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    const data = await response.json()
    if(response.status===200){
      dispatch(deleteSuccess(data))
      dispatch(showSuccess("machine.success.delete"))
    }else if(response.status===403){
      dispatch(logout())
    }else{
      dispatch(showError(data.message))
    }
  }
}
