import { configureStore } from '@reduxjs/toolkit';
import mainReducer from '../features/main/mainSlice';
import loginReducer from '../features/login/loginSlice';
import usersReducer from '../features/users/usersSlice';
import userReducer from '../features/users/userSlice';
import machineTypesReducer from '../features/machineTypes/machineTypesSlice';
import machineTypeReducer from '../features/machineTypes/machineTypeSlice';
import plantsReducer from '../features/plants/plantsSlice';
import machinesReducer from '../features/machines/machinesSlice';
import notificationsReducer from '../features/notifications/notificationsSlice';
import resetReducer from '../features/reset/resetSlice';

export default configureStore({
  reducer: {
    main: mainReducer,
    login: loginReducer,
    users: usersReducer,
    user:userReducer,
    plants:plantsReducer,
    machines:machinesReducer,
    notifications:notificationsReducer,
    machineTypes:machineTypesReducer,
    machineType:machineTypeReducer,
    reset:resetReducer
  },
});
