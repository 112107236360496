import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import T from 'i18n-react';
import { usersSelector, getAPI, setReload } from './usersSlice'
import TableList from '../utility/TableList'
import { machineTypesSelector, getAPI as getMachineTypes } from '../machineTypes/machineTypesSlice'

import {
  Box,
} from "@material-ui/core";

const UsersPage = () => {
  const dispatch = useDispatch()
  const { users, loading, results, reload } = useSelector(usersSelector);
  const { machineTypes } = useSelector(machineTypesSelector);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("companyName");
  const [companyName, setCompanyName] = React.useState(false);
  const [machineType, setMachineType] = React.useState(false);

  useEffect(() => {
    dispatch(getAPI(page, rowsPerPage, orderBy, order, companyName, machineType));
    dispatch(getMachineTypes())
  }, [dispatch, page, rowsPerPage, orderBy, order, companyName, machineType]);

  if (reload && !loading) {
    dispatch(getAPI(page, rowsPerPage, orderBy, order, companyName, machineType));
  }

  const handleChangePage = (event, newPage) => {
    dispatch(getAPI(page, rowsPerPage, orderBy, order, companyName, machineType));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch(getAPI(0, event.target.value, orderBy, order, companyName, machineType));
    setPage(0);
  };

  const onRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    let newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    dispatch(getAPI(page, rowsPerPage, orderBy, order, companyName, machineType));
  };

  const changeFilters = (name, value) => {
    const f =
      name === "companyName" ? setCompanyName :
      name === "machineTypes" ? setMachineType : null;
    f(value);
    dispatch(setReload());
  };

  const resetFilters = () => {
    setCompanyName(false)
    setMachineType(false)
  }

  const headers = {
    companyName: { filter: true, type: "string", sort: true },
    username: { filter: false, type: "string", sort: true },
    vatNumber: { filter: false, type: "string", sort: true },
    recoveryEmail: { filter: false, type: "string", sort: true },
    machineTypes: { filter: true, type: "list", sort: false }
  };

  return (
    <Box>
      {users !== false ? (
        <TableList items={users} headers={headers} mainObject={"user."} redirectObject={"users"} page={page} 
          setPage={handleChangePage} rowsPerPage={rowsPerPage} setRowsPerPage={handleChangeRowsPerPage} results={results}
          onRequestSort={onRequestSort} order={order} orderBy={orderBy} companyNameList={false} machineTypesList={machineTypes} changeFilters={changeFilters}
          resetFilters={resetFilters}/>
      ) : null}
      {loading ? <div>{T.translate("function.loading")}</div> : null}
    </Box>
  )
}

export default UsersPage
