import { createSlice } from '@reduxjs/toolkit'
import { SERVER_URL } from '../../constants';
import { showError } from '../notifications/notificationsSlice';
import { logout } from '../main/mainSlice';
import { reset } from './machineTypeSlice';

export const initialState = {
  loading: false,
  hasErrors: false,
  machineTypes: [],
}

const machineTypesSlice = createSlice({
  name: 'machineTypes',
  initialState,
  reducers: {
    getting: state => {
      state.loading = true
    },
    getSuccess: (state, { payload }) => {
      state.machineTypes = payload
      state.loading = false
      state.hasErrors = false
    },
  }
})

export const { getting, getSuccess } = machineTypesSlice.actions
export const machineTypesSelector = state => state.machineTypes
export default machineTypesSlice.reducer

export function getAPI() {
  let access_token = ""
  if(localStorage.getItem('bishop_current_user')!=null){
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
      dispatch(getting())
      dispatch(reset())
      const response = await fetch(SERVER_URL+'/api/machineType', {
        mode: 'cors',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'Authorization': 'Bearer ' + access_token
        }
      });

      const data = await response.json()
      if(response.status===200){
        dispatch(getSuccess(data))
      }else if(response.status===403){
        dispatch(logout())
      }else{
        dispatch(showError(data.message))
      }
  }
}
