import React from 'react'
import { Link } from 'react-router-dom'
import { Typography, Container, FormControl, InputLabel, Input, Divider, Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, FormControlLabel, Checkbox } from '@material-ui/core'
import T from 'i18n-react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(3),
    },
  },
  buttonContainer:{
    textAlign:"right"
  }
}));

export const Details = ({ user, edit, toggleDelete, deleting, doDelete }) => {

  const classes = useStyles();

  return (
    <Container maxWidth={false}>
      <form className={classes.root} noValidate autoComplete="off">
        <Typography component="h1" variant="h6" color="inherit"> {T.translate("user.user")}</Typography>
          <FormControl disabled>
            <InputLabel htmlFor="username">{T.translate("user.email")}</InputLabel>
            <Input id="username" value={user.username} />
          </FormControl>
          <FormControl disabled>
            <InputLabel htmlFor="companyName">{T.translate("user.companyName")}</InputLabel>
            <Input value={user.companyName} />
          </FormControl>
          <FormControl disabled>
            <InputLabel htmlFor="username">{T.translate("user.vatNumber")}</InputLabel>
            <Input value={user.vatNumber} />
          </FormControl>
          <FormControl disabled>
            <InputLabel htmlFor="phoneNumber">{T.translate("user.phoneNumber")}</InputLabel>
            <Input value={user.phoneNumber} />
          </FormControl>
          <FormControl disabled>
            <InputLabel htmlFor="recoveryEmail">{T.translate("user.recoveryEmail")}</InputLabel>
            <Input value={user.recoveryEmail} />
          </FormControl>
          <FormControl disabled>
            <InputLabel htmlFor="referente">{T.translate("user.referente")}</InputLabel>
            <Input value={user.referente} />
          </FormControl>
          <FormControlLabel disabled control={<Checkbox checked={user.enabled} color="default"/>} label={T.translate("user.enabled")} />
          <FormControlLabel disabled control={<Checkbox checked={user.notifyAlert} color="default"/>} label={T.translate("user.notifyAlert")} />
          <div>
            <InputLabel htmlFor="roles">{T.translate("user.roles")}</InputLabel>
            {user.authorities.map(a=>{
              return <FormControlLabel key={a} disabled control={<Checkbox checked={true}/>} label={a} />
            })}
          </div>
        </form>
      <Divider/>
      <div className={classes.buttonContainer}>
        <Button focusRipple key={user.id} onClick={edit} color="primary">{T.translate("button.edit")}</Button>
        <Button color="secondary" onClick={toggleDelete}>{T.translate("button.delete")}</Button>
        <Button component={Link} to={`/users`}>{T.translate("button.back")}</Button>
      </div>
      <Dialog
        open={deleting}
        keepMounted
        onClose={toggleDelete}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{T.translate("function.confirm")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{T.translate("function.confirmDelete")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={doDelete} color="secondary">{T.translate("button.confirm")}</Button>
          <Button onClick={toggleDelete} color="default">{T.translate("button.cancel")}</Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}
