import { createSlice } from '@reduxjs/toolkit'
import { SERVER_URL } from '../../constants';

export const initialState = {
  loading: false,
  hasErrors: false,
  user: {username:'',password:''},
}

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    showError: state => {
      state.loading = false
      state.hasErrors = true
    },
    change: (state, { payload }) => {
      let u = state.user
      u[payload.name] = payload.value
      state.user = u
    },
    login: state => {
      state.loading = true
      state.hasErrors = false
    },
    loginSuccess: (state, { payload }) => {
      localStorage.setItem('bishop_current_user', JSON.stringify(payload))
      state.loading = false
      state.hasErrors = false
    }
  }
})

export const { showError, change, login, loginSuccess, cancelAuthenticated } = loginSlice.actions
export const loginSelector = state => state.login
export default loginSlice.reducer

export function loginAPI(user) {
  return async dispatch => {
    dispatch(login())
    try {
      let params = {username:user.username,password:user.password}
      const response = await fetch(SERVER_URL+'/api/login', {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(params)
      });

      const data = await response.json()
      dispatch(loginSuccess(data))
    } catch (error) {
      dispatch(showError())
    }
  }
}
