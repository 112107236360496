import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import T from 'i18n-react';
import { plantsSelector, getAPI, create, edit, cancelEdit, change, createAPI, updateAPI, select, confirmDelete, deleteAPI } from './plantsSlice'
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  Divider,
} from "@material-ui/core";

import { Form } from './Form'
import { Item } from './Item'
import { Details } from './Details'
import { MachinesPage } from '../machines/MachinesPage'
import { reset as resetMachines } from '../machines/machinesSlice';

const useStyles = makeStyles((theme) => ({
  root: {
      margin: theme.spacing(3),
      padding: theme.spacing(2)
  },
  buttonContainer:{
    textAlign:"right"
  }
}));

export const  PlantsPage = ({user}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { plants, editplant, plant, editmode, deleting, isChanged } = useSelector(plantsSelector)

  useEffect(() => {
    dispatch(getAPI(user))
  }, [dispatch, user])

  if(isChanged){
    dispatch(getAPI(user))
  }

  const handleChange = event => {
    let name = event.target.name
    let value = event.target.value
    dispatch(change({name: name, value: value }));
  }

  const save = () => {
    if(editplant.id==="new"){
      dispatch(createAPI(user, editplant));
    }else{
      dispatch(updateAPI(user, editplant));
    }
  };

  const onSelect = (plant) => {
    dispatch(select(plant))
    dispatch(resetMachines())
  }

  const onEdit = () => {
    dispatch(edit())
  }
  const toggleDelete = () => {
    dispatch(confirmDelete())
  }

  const doDelete = () => {
    dispatch(deleteAPI(user, plant))
  }

  return (
    <Paper className={classes.root}>
      <Grid container spacing={8}>
        <Grid item xs={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><Typography component="h1" variant="h6" color="inherit"> {T.translate("plant.plants")}</Typography></TableCell>
                <TableCell><Button color="primary" onClick={()=>dispatch(create())}>{T.translate("button.add")}</Button></TableCell>
              </TableRow>
            </TableHead>
            {plants?(
              <TableBody>
                  {plants.map(p=>{
                    return(
                      <Item key={p.id} plant={plant} p={p} select={onSelect}/>
                    )
                  })}
              </TableBody>
            ):null}
          </Table>
        </Grid>
        <Grid item xs={9}>
          {plant?
            <div>
              <Details plant={plant} edit={onEdit} toggleDelete={toggleDelete} deleting={deleting} doDelete={doDelete}/>
              <Divider/>
              <MachinesPage user={user} plant={plant}/>
            </div>
          :null}
        </Grid>
      </Grid>
      <Dialog
        open={editmode}
        keepMounted
        maxWidth={"sm"}
        fullWidth={true}
        onClose={()=>dispatch(cancelEdit())}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{T.translate("plant.plant")}</DialogTitle>
          <DialogContent>
            <Form editplant={editplant} handleChange={handleChange} />
          </DialogContent>
          <DialogActions>
            <Button onClick={save} color="primary">{T.translate("button.save")}</Button>
            <Button onClick={()=>dispatch(cancelEdit())} color="default">{T.translate("button.cancel")}</Button>
          </DialogActions>
        </Dialog>
    </Paper>
  )
}
