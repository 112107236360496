import React from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Button, TableSortLabel, TextField} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check"
import T from "i18n-react";
import { bishopMoment } from "../../constants";
import { Autocomplete } from "@material-ui/lab";
import classNames from "classnames";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  selected:{
    backgroundColor:"#0000001a"
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  actionCell: {
    textAlign: "right",
  },
}));

export default function EnhancedTable(props) {
  const { items, headers, mainObject, redirectObject, page, setPage, rowsPerPage, setRowsPerPage, results,
    onRequestSort, order, orderBy, changeFilters, resetFilters } = props;
  const classes = useStyles();
  const [filter, setFilter] = React.useState(false);
  const emptyRows = rowsPerPage - items.length;


  const toggleFilter = () => {
    setFilter(!filter);
    if(filter){
      resetFilters()
    }
  };

  const onChangeFilterAutocomplete = (event, value, reason, name) => {
    if (reason === "clear") {
      changeFilters(name, false);
    } else {
      changeFilters(name, value);
    }
  };
  
  const onChangeFilter = (event) => {
    changeFilters(event.target.name, event.target.value);
  };

  const returnCheck= (val) => {
    if (val) {
      return <CheckIcon/>}
    else {
      return null}
  }

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              {Object.keys(headers).map((h) => {
                return (
                  <TableCell key={h} align={ headers[h].type === "numeric" || headers[h].type === "date" || headers[h].type === "datetime" ? "right": "left"} sortDirection={orderBy === h ? order : false}>
                    {headers[h].sort?(
                        <TableSortLabel active={orderBy === h} direction={orderBy === h ? order : "asc"} onClick={() => onRequestSort(h)}>
                        {T.translate(mainObject + h)}
                        {orderBy === h ? (<span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span>) : null}
                        </TableSortLabel>
                    ):<span>{T.translate(mainObject + h)}</span>}
                  </TableCell>
                );
              })}
              <TableCell className={classes.actionCell}>
                <Button className={classNames({[classes.selected]: filter})} color="default" onClick={toggleFilter}>{T.translate("function.filter")}</Button>
                <Button component={Link} color="primary" to={`/${redirectObject}/new`}>{T.translate("button.add")}</Button>
              </TableCell>
            </TableRow>
            {filter ? (
              <TableRow>
                {Object.keys(headers).map((h) => {
                  if (headers[h].filter) {
                    return (
                      <TableCell key={"filter" + h}>
                        {props[h + "List"] !== false ? (
                          <Autocomplete options={props[h + "List"]} filterSelectedOptions 
                            onChange={(event, value, reason) => onChangeFilterAutocomplete(event,value,reason,h)} 
                            getOptionLabel={(option) => headers[h].type === "object" || headers[h].type === "list"? option.name: option}
                            renderInput={(params) => (<TextField {...params} placeholder={T.translate(mainObject + h)} />)}/>) : null}
                        {props[h + "List"] === false ? (<TextField name={h} onChange={onChangeFilter} />) : null}
                      </TableCell>
                    );
                  } else {
                    return <TableCell key={"filter" + h}></TableCell>;
                  }
                })}
                <TableCell></TableCell>
              </TableRow>
            ) : null}
          </TableHead>
          <TableBody>
            {items.map((row) => {
              return (
                <TableRow key={row.id} style={{ textDecoration: "none" }}>
                  {Object.keys(headers).map((h) => {
                    let obj = headers[h];
                    if (row[h] !== null) {
                      return (
                        <TableCell
                          key={row.id + h} align={ obj.type === "numeric" || obj.type === "date" || obj.type === "datetime" ? "right" : "justify"}>
                          {obj.type === "object" ? row[h].name : obj.type === "date"? bishopMoment(row[h]).format("L"): obj.type === "datetime" ? bishopMoment(row[h]).format("LTS"): obj.type === "boolean"? returnCheck(row[h]): obj.type==="list"? row[h].join(", "): row[h]}
                        </TableCell>
                      );
                    } else {
                      return <TableCell key={row.id + h}></TableCell>;
                    }
                  })}
                  <TableCell className={classes.actionCell}>
                    <Button color="default" component={Link} to={`/${redirectObject}/${row.id}`}>{T.translate("button.details")}</Button>
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 33 * emptyRows }}>
                <TableCell colSpan={Object.keys(headers).length+1} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination rowsPerPageOptions={[2, 5, 10, 25]} component="div" count={results} rowsPerPage={rowsPerPage} page={page} onChangePage={setPage} onChangeRowsPerPage={setRowsPerPage}/>
    </div>
  );
}
