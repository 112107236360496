import React from 'react';
import { Typography, Container } from '@material-ui/core';
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import UserDataPage from './UserDataPage';
import T from 'i18n-react';

import {
  mainSelector,
} from '../main/mainSlice'


const HomePage = () => {
  const { isAdmin, isUser, currentUser } = useSelector(mainSelector)

  if(isAdmin){
    return (
        <Redirect to={"/users"}/>
    )
  }
  if(isUser&&currentUser){
    return (
      <Container maxWidth={false}>
        <Typography component="h1" variant="h6" color="inherit"> {T.translate("function.welcome")} {currentUser.companyName}</Typography>
        <UserDataPage user={currentUser}/>
      </Container>
    )
  }
  return null;
}
export default HomePage;
