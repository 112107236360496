import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Redirect,
} from 'react-router-dom'
import {
  machineTypeSelector,
  getAPI,
  updateAPI,
  createAPI,
  deleteAPI,
  edit,
  cancelEdit,
  change,
  create,
  confirmDelete,
} from './machineTypeSlice'

import {
  Box,
} from "@material-ui/core";

import { Form } from './Form'
import { Details } from './Details'

const MachineTypePage = ({ match }) => {
  const dispatch = useDispatch()
  const { machineType, machineTypeedit, editmode, deleting, isCreated, isDeleted } = useSelector(machineTypeSelector)

  const handleChange = event => {
    let name = event.target.name
    let value = event.target.value
    if(event.target.type==="checkbox"){
      value = event.target.checked
    }
    dispatch(change({name: name, value: value }));
  };

  const toggleDelete = event => {
    dispatch(confirmDelete());
  };

  const onCancelEdit = () => {
    dispatch(cancelEdit(machineTypeedit))
  }

  const onEdit = () => {
    dispatch(edit())
  }
  const doDelete = () => {
    dispatch(deleteAPI(machineType))
  }

  const save = () => {
    if(machineType.id==="new"){
      dispatch(createAPI(machineTypeedit));
    }else{
      dispatch(updateAPI(machineTypeedit));
    }
  };

  useEffect(() => {
    const { id } = match.params
    if(id!=="new"){
      dispatch(getAPI(id))
    }else{
      dispatch(create())
    }
  }, [dispatch, match])

  const renderMachineType = () => {
    if(machineType!==false&&!editmode&&match.params.id===machineType.id.toString()) return (
      <Details machineType={machineType} edit={onEdit} toggleDelete={toggleDelete} deleting={deleting} doDelete={doDelete}/>
    )
    if(machineType!==false&&editmode) return <Form machineTypeedit={machineTypeedit} handleChange={handleChange} cancelEdit={onCancelEdit} save={save}/>
    return
  }

  if(isDeleted&&match.params.id===machineType.id.toString()){
    return (<Redirect to={"/machineTypes/"}/>)
  }

  if(isCreated){
    return (<Redirect to={"/machineTypes/"+machineType.id}/>)
  }

  return (
    <Box>
      {renderMachineType()}
    </Box>
  )
}

export default MachineTypePage
